<template>
  <div>
    <DashboardLayout>
      <div class="card">

        <div class="content-body">

          <div v-if="!licenceStore.chatbot">
            <div class="alert alert-danger" style="padding: 10px;">
              ⚠️ Actuellement, vous ne disposez pas de licence 'chatbot' ou celle-ci a déjà expiré.
            </div>
          </div>

          <div v-if="licenceStore.chatbot && licenceStore.chatbot_statut_type == 'trial' ">
            <div class="alert alert-success" style="padding:10px;">
              Votre licence 'chatbot' est en période d’essai juqu'au {{licenceStore.chatbot_end}}.<br>
              Vous pouvez dès à présent souscrire à un abonnement.
            </div>
          </div>

          <div v-if="licenceStore.chatbot && licenceStore.chatbot_statut_type == 'paid' && !enableModuleOnly()">
            <div class="alert alert-success" style="padding:10px;">
              Votre licence 'chatbot' est valide juqu'au {{licenceStore.chatbot_end}}.<br>
              Vous pouvez dès à présent renouveler votre licence.
            </div>
          </div>

          <section id="pricing-plan">

            <div class="text-center mt-4 mb-4" style="font-size: 18px" v-if="this.enableModuleOnly()">
              <h1 style="font-size: 40px">Mes modules</h1>
              Vous pouvez rajouter ici un module manquant jusqu'à 90 jours avant expiration de votre licence.
            </div>

            <div class="text-center mt-4" style="font-size: 18px" v-if="!this.enableModuleOnly()">
              <h1 style="font-size: 40px">Plans et Tarifs</h1>
              Choisissez l’un de nos packs ou personnalisez votre plan "à la carte"
            </div>

            <div class="pricing-table" v-if="!this.enableModuleOnly()">
              <div>
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex align-items-center justify-content-center">
                      <span>
                        <input class="custom-option-item-check" type="radio" id="pack" :value="'pack'" v-model="this.current_page">
                        <label style="width:150px" class="cursor-pointer center custom-option-item custom-option-item-left p-1" for="pack">
                          Packs
                        </label>
                      </span>
                      <span>
                        <input class="custom-option-item-check" type="radio" id="carte" :value="'carte'" v-model="this.current_page">
                        <label style="width:150px" class=" cursor-pointer center custom-option-item custom-option-item-right p-1" for="carte">
                          À la carte
                        </label>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="pricing-table" v-if="current_page == 'pack' ">
              <div class="row" id="table-contextual">
                <div class="col-12">
                  <div class="card">
                    <div class="table-responsive">
                      <table class="table table-striped table-borderless">
                        <tbody>
                        <tr>
                          <td></td>
                          <td></td>
                          <td class="center popular">POPULAIRE</td>
                          <td> </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>
                            <div class="text-center">
                              <div class="card-body">
                                <img src="@/assets/images/ico-starter.png" height="50" />
                                <h3 class="mt-1">Starter</h3>
                                <p class="card-text">Le minimum pour générer et identifier des leads qualifiés.</p>
                                <div class="annual-plan">
                                  <div class="plan-price">
                            <span
                                    class="pricing-basic-value fw-bolder text-primary"
                            >{{numberWithCommas(productStore.starter.price)}}€</span
                            >
                                    <div
                                            class="pricing-duration text-body font-medium-1 fw-bold"
                                    >H.T / an</div
                                    >
                                  </div>
                                </div>
                                <a class="w-100 mt-2 waves-effect btn btn-primary" @click="checkoutPack(productStore.starter)" >Acheter maintenant</a>

                              </div>
                            </div>
                          </td>
                          <td class="regular-pricing">
                            <div class="text-center">
                              <div class="card-body">
                                <img src="@/assets/images/ico-regular.png" height="50" />
                                <h3 class="mt-1">Regular</h3>
                                <p class="card-text">L’incontournable pour les écoles à fort potentiel.</p>
                                <div class="annual-plan">
                                  <div class="plan-price">
                            <span
                                    class="pricing-basic-value fw-bolder text-primary"
                            >{{numberWithCommas(productStore.standard.price)}}€</span
                            >
                                    <div
                                            class="pricing-duration text-body font-medium-1 fw-bold"
                                    >H.T / an</div
                                    >
                                  </div>
                                </div>
                                <a class="w-100 mt-2 waves-effect btn btn-primary" @click="checkoutPack(productStore.standard)" >Acheter maintenant</a>

                              </div>
                            </div>
                          </td>
                          <td><div class="basic-pricing text-center">
                            <div class="card-body">
                              <img src="@/assets/images/ico-booster.png" height="50" />
                              <h3 class="mt-1">Booster</h3>
                              <p class="card-text">La solution pour les groupes et les écoles ambitieuses.</p>
                              <div class="annual-plan">
                                <div class="plan-price">
                            <span
                                    class="pricing-basic-value fw-bolder text-primary"
                            >{{numberWithCommas(productStore.booster.price)}}€</span
                            >
                                  <div
                                          class="pricing-duration text-body font-medium-1 fw-bold"
                                  >H.T / an</div
                                  >
                                </div>
                              </div>
                              <a class="w-100 mt-2 waves-effect btn btn-primary" @click="checkoutPack(productStore.booster)" >Acheter maintenant</a>

                            </div>
                          </div></td>
                        </tr>

                        <tr class="table-default">
                          <td>Nombre de leads</td>
                          <td class="center">500</td>
                          <td class="center table-primary">1,500</td>
                          <td class="center">5,000</td>
                        </tr>

                        <tr class="table-default">
                          <td>Prénom / Nom / Email / Téléphone</td>
                          <td class="center">&#10004;</td>
                          <td class="center table-primary">&#10004;</td>
                          <td class="center">&#10004;</td>
                        </tr>

                        <tr class="table-default">
                          <td>Leads Étudiants / Parents</td>
                          <td class="center">&#10004;</td>
                          <td class="center table-primary">&#10004;</td>
                          <td class="center">&#10004;</td>
                        </tr>

                        <tr class="table-default">
                          <td>Leads Salariés</td>
                          <td class="center">&#10004;</td>
                          <td class="center table-primary">&#10004;</td>
                          <td class="center">&#10004;</td>
                        </tr>

                        <tr class="table-default">
                          <td>Leads Entreprises</td>
                          <td class="center">-</td>
                          <td class="center table-primary">&#10004;</td>
                          <td class="center">&#10004;</td>
                        </tr>

                        <tr class="table-default">
                          <td>Connexion au CRM</td>
                          <td class="center">&#10004;</td>
                          <td class="center table-primary">&#10004;</td>
                          <td class="center">&#10004;</td>
                        </tr>

                        <tr class="table-default">
                          <td>Aiguillage Multi-campus</td>
                          <td class="center">Illimité</td>
                          <td class="center table-primary">Illimité</td>
                          <td class="center">Illimité</td>
                        </tr>

                        <tr class="table-default">
                          <td>Wizz Reply Étudiants</td>
                          <td class="center">&#10004;</td>
                          <td class="center table-primary">&#10004;</td>
                          <td class="center">&#10004;</td>
                        </tr>

                        <tr class="table-default">
                          <td>Wizz Reply Entreprises</td>
                          <td class="center">-</td>
                          <td class="center table-primary">&#10004;</td>
                          <td class="center">&#10004;</td>
                        </tr>

                        <tr class="table-default">
                          <td>Tracking Leads Adwords</td>
                          <td class="center">-</td>
                          <td class="center table-primary">&#10004;</td>
                          <td class="center">&#10004;</td>
                        </tr>

                        <tr class="table-default">
                          <td>Tracking Leads réseaux sociaux</td>
                          <td class="center">-</td>
                          <td class="center table-primary">&#10004;</td>
                          <td class="center">&#10004;</td>
                        </tr>

                        <tr class="table-default">
                          <td>Tracking Leads (UTM)</td>
                          <td class="center">-</td>
                          <td class="center table-primary">&#10004;</td>
                          <td class="center">&#10004;</td>
                        </tr>

                        <tr class="table-default">
                          <td>Post Lead-ia (Bannières)</td>
                          <td class="center">&#10004;</td>
                          <td class="center table-primary">&#10004;</td>
                          <td class="center">&#10004;</td>
                        </tr>

                        <tr class="table-default">
                          <td>Création de votre avatar</td>
                          <td class="center">&#10004;</td>
                          <td class="center table-primary">&#10004;</td>
                          <td class="center">&#10004;</td>
                        </tr>

                        <tr class="table-default">
                          <td>Filtrage géographique</td>
                          <td class="center">-</td>
                          <td class="center table-primary">&#10004;</td>
                          <td class="center">&#10004;</td>
                        </tr>

                        <tr class="table-default">
                          <td>Nombre d'utilisateurs</td>
                          <td class="center">Illimité</td>
                          <td class="center table-primary">Illimité</td>
                          <td class="center">Illimité</td>
                        </tr>

                        <tr class="table-default">
                          <td>Magic SMS + 1000 crédits offerts</td>
                          <td class="center">&#10004;</td>
                          <td class="center table-primary">&#10004;</td>
                          <td class="center">&#10004;</td>
                        </tr>

                        <tr class="table-default">
                          <td>Support</td>
                          <td class="center">&#10004;</td>
                          <td class="center table-primary">&#10004;</td>
                          <td class="center">&#10004;</td>
                        </tr>

                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="pricing-table" v-if="current_page == 'carte' ">
              <div class="row">
                <div class="col-8">
                  <h2 v-if="!this.enableModuleOnly()">Base</h2>
                  <div class="row mb-4" v-if="!this.enableModuleOnly()">
                    <div class="col-lg-4 col-md-6 col-12">
                      <div class="border_shadow" style="margin-top:25px">
                        <div class="card-body">
                          <div class="d-flex mb-0">
                            <div class="form-check" style="padding-top:20px;width:35px">
                              <input type="radio" class="form-check-input"  id="planStarter" :value="productStore.lead_500" v-model="this.current_plan">
                            </div>
                            <label for="planStarter" class="cursor-pointer">
                              <h6 class="blog-recent-post-title">
                                Starter <img src="@/assets/images/ico-starter.png" height="20" />
                              </h6>
                              <div class="text-muted mb-0">500 leads max</div>
                              <div class="text-muted mb-0">{{ pricePerMonth(productStore.lead_500) }} € H.T / mois*</div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-12" v-if="!this.enableModuleOnly()">
                      <div class="center popular">POPULAIRE</div>
                      <div class="border_shadow">
                        <div class="card-body">
                          <div class="d-flex mb-0">
                            <div class="form-check" style="padding-top:20px;width:35px">
                              <input type="radio" class="form-check-input" id="planRegular" :value="productStore.lead_1500" v-model="this.current_plan">
                            </div>
                            <label for="planRegular" class="cursor-pointer">
                              <h6 class="blog-recent-post-title">
                                Regular <img src="@/assets/images/ico-regular.png" height="20" />
                              </h6>
                              <div class="text-muted mb-0">1,500 leads max</div>
                              <div class="text-muted mb-0">{{ pricePerMonth(productStore.lead_1500) }} € H.T / mois*</div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-12" v-if="!this.enableModuleOnly()">
                      <div class="border_shadow" style="margin-top:25px">
                        <div class="card-body">
                          <div class="d-flex mb-0">
                            <div class="form-check" style="padding-top:20px;width:35px">
                              <input type="radio" class="form-check-input" id="planBooster" :value="productStore.lead_5000" v-model="this.current_plan">
                            </div>
                            <label for="planBooster" class="cursor-pointer">
                              <h6 class="blog-recent-post-title">
                                Booster <img src="@/assets/images/ico-booster.png" height="20" />
                              </h6>
                              <div class="text-muted mb-0">5,000 leads et +</div>
                              <div class="text-muted mb-0">{{ pricePerMonth(productStore.lead_5000) }} € H.T / mois*</div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <h2>Modules</h2>
                  <div class="row mb-1" :class="enableModuleOnly() && this.licenceStore.chatbot_tracking ? 'grayscale' : ''">
                    <div class="col-12">
                      <div class="border_shadow">
                        <div class="card-module">
                          <input type="checkbox" class="form-check-input input-filter" id="tracking" v-model="this.tracking_selected">
                          <label class="form-check-label" for="tracking">
                            Tracking
                          </label>
                          <span class="check-price">{{pricePerMonth(productStore.tracking)}} € H.T / mois*</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mb-1" :class="enableModuleOnly() && this.licenceStore.chatbot_interaction ? 'grayscale' : ''">
                    <div class="col-12">
                      <div class="border_shadow">
                        <div class="card-module">
                          <input type="checkbox" class="form-check-input input-filter" id="interaction" v-model="this.interaction_selected">
                          <label class="form-check-label" for="interaction">
                            Interactions
                          </label>
                          <span class="check-price">{{pricePerMonth(productStore.interaction)}} € H.T / mois*</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mb-1" :class="enableModuleOnly() && this.licenceStore.chatbot_personnalisation ? 'grayscale' : ''">
                    <div class="col-12">
                      <div class="border_shadow">
                        <div class="card-module">
                          <input type="checkbox" class="form-check-input input-filter" id="personnalisation" v-model="this.personnalisation_selected">
                          <label class="form-check-label" for="personnalisation">
                            Personnalisation
                          </label>
                          <span class="check-price">{{pricePerMonth(productStore.personnalisation)}} € H.T / mois*</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mb-1" :class="enableModuleOnly() && this.licenceStore.chatbot_entreprise ? 'grayscale' : ''">
                    <div class="col-12">
                      <div class="border_shadow">
                        <div class="card-module">
                          <input type="checkbox" class="form-check-input input-filter" id="entreprise" v-model="this.entreprise_selected">
                          <label class="form-check-label" for="entreprise">
                            Entreprises
                          </label>
                          <span class="check-price">{{pricePerMonth(productStore.entreprise)}} € H.T / mois*</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mb-1" :class="enableModuleOnly() && this.licenceStore.chatbot_crm ? 'grayscale' : ''">
                    <div class="col-12">
                      <div class="border_shadow">
                        <div class="card-module">
                          <input type="checkbox" class="form-check-input input-filter" id="crm" v-model="this.crm_selected">
                          <label class="form-check-label" for="crm">
                            Connexion CRM
                          </label>
                          <span class="check-price">{{pricePerMonth(productStore.crm)}} € H.T / mois*</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mb-1" :class="enableModuleOnly() && this.licenceStore.chatbot_sms ? 'grayscale' : ''">
                    <div class="col-12">
                      <div class="border_shadow">
                        <div class="card-module">
                          <input type="checkbox" class="form-check-input input-filter" id="sms" v-model="this.sms_selected">
                          <label class="form-check-label" for="sms">
                            Magic SMS
                          </label>
                          <span class="check-price">{{pricePerMonth(productStore.starter_sms)}} € H.T / mois*</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mb-1" :class="enableModuleOnly() && this.licenceStore.chatbot_support ? 'grayscale' : ''">
                    <div class="col-12">
                      <div class="border_shadow">
                        <div class="card-module">
                          <input type="checkbox" class="form-check-input input-filter" id="support" v-model="this.support_selected">
                          <label class="form-check-label" for="support">
                            Support
                          </label>
                          <span class="check-price">{{pricePerMonth(productStore.support)}} € H.T / mois*</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <span>* Facturé annuellement</span>
                </div>
                <div class="col-4" v-if="current_plan">
                  <h2>Détails de votre plan</h2>

                  <div class="row mt-2 mb-1" v-if="!this.enableModuleOnly()">
                    <div class="col-12">
                      <div style="padding:5px 5px 5px 0">
                        <div>
                          <span class="form-check-label">
                            {{current_plan.name}}
                          </span>
                          <span style="float:right" v-if="current_plan.name">{{current_plan.name.split(" ")[1]}} {{ current_plan.name == "Lead 5000" ? "leads et +" : "leads max" }}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mb-1" v-if="!(this.enableModuleOnly() && this.licenceStore.chatbot_tracking)">
                    <div class="col-12">
                      <div style="padding:5px 5px 5px 0">
                        <div>
                          <span class="form-check-label">
                            Tracking
                          </span>
                          <span style="float:right">{{ tracking_selected ? "&#10004;" : "-" }}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mb-1" v-if="!(this.enableModuleOnly() && this.licenceStore.chatbot_interaction)">
                    <div class="col-12">
                      <div style="padding:5px 5px 5px 0">
                        <div>
                          <span class="form-check-label">
                            Interactions
                          </span>
                          <span style="float:right">{{ interaction_selected ? "&#10004;" : "-" }}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mb-1" v-if="!(this.enableModuleOnly() && this.licenceStore.chatbot_personnalisation)">
                    <div class="col-12">
                      <div style="padding:5px 5px 5px 0">
                        <div>
                          <span class="form-check-label">
                            Personnalisation
                          </span>
                          <span style="float:right">{{ personnalisation_selected ? "&#10004;" : "-" }}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mb-1" v-if="!(this.enableModuleOnly() && this.licenceStore.chatbot_entreprise)">
                    <div class="col-12">
                      <div style="padding:5px 5px 5px 0">
                        <div>
                          <span class="form-check-label">
                            Entreprises
                          </span>
                          <span style="float:right">{{ entreprise_selected ? "&#10004;" : "-" }}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mb-1" v-if="!(this.enableModuleOnly() && this.licenceStore.chatbot_crm)">
                    <div class="col-12">
                      <div style="padding:5px 5px 5px 0">
                        <div>
                          <span class="form-check-label">
                            Connexion CRM
                          </span>
                          <span style="float:right">{{ crm_selected ? "&#10004;" : "-" }}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mb-1" v-if="!(this.enableModuleOnly() && this.licenceStore.chatbot_sms)">
                    <div class="col-12">
                      <div style="padding:5px 5px 5px 0">
                        <div>
                          <span class="form-check-label">
                            Magic SMS
                          </span>
                          <span style="float:right">{{ sms_selected ? "&#10004;" : "-" }}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mb-1" v-if="!(this.enableModuleOnly() && this.licenceStore.chatbot_support)">
                    <div class="col-12">
                      <div style="padding:5px 5px 5px 0">
                        <div>
                          <span class="form-check-label">
                            Support
                          </span>
                          <span style="float:right">{{ support_selected ? "&#10004;" : "-" }}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr>

                  <div class="row mb-1">
                    <div class="col-12">
                      <div style="padding:5px 5px 5px 0">
                        <div>
                          <span class="form-check-label">
                            Total
                          </span>
                          <span style="float:right" class="bold">
                            <div class="pricing-basic-value fw-bolder text-primary">{{numberWithCommas(selectionTotal())}} €</div>
                            <div style="float:right" class="pricing-duration text-body font-medium-1 fw-bold">H.T / an</div>
                          </span>
                        </div>

                        <a class="w-100 mt-2 waves-effect btn btn-primary" v-if="this.selectionTotal() > 0" @click="this.checkoutCustom()" >Acheter ce plan</a>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="pricing-table mt-2" v-if="current_page == 'carte' ">
              <div class="row" >
                <div class="col-12">
                  <div class="row g-0">

                    <div class="col-lg-4 col-md-6 col-12">
                      <div class="card card-apply-job">
                        <div class="card-body" style="padding:5px">
                          <div class="apply-job-package bg-light-primary rounded">
                            <div>
                              <h2 class="d-inline me-25">Base</h2>
                            </div>
                            <small>à partir de 99.9 € H.T / mois*</small>
                          </div>
                          <ul class="features">
                            <li>Flow «Étudiants et Parents»</li>
                            <li>Flow «Salariés / Demandeur d'emploi»</li>
                            <li>Leads (nom, prénom, email, téléphone)</li>
                            <li>Synthèse journalière</li>
                            <li>Extraction simple</li>
                            <li>Plusieurs comptes "Utilisateurs"</li>
                            <li>Multi-campus</li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-12">
                      <div class="card card-apply-job">
                        <div class="card-body" style="padding:5px">
                          <div class="apply-job-package bg-light-primary rounded">
                            <div>
                              <h2 class="d-inline me-25">Interactions</h2>
                            </div>
                            <small>{{pricePerMonth(productStore.interaction)}} € H.T / mois*</small>
                          </div>
                          <ul class="features">
                            <li>Les posts Lead-ia</li>
                            <li>Demandes de contact</li>
                            <li>Click to Call</li>
                            <li>Extraction complète</li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-12">
                      <div class="card card-apply-job">
                        <div class="card-body" style="padding:5px">
                          <div class="apply-job-package bg-light-primary rounded">
                            <div>
                              <h2 class="d-inline me-25">Personnalisation</h2>
                            </div>
                            <small>{{pricePerMonth(productStore.personnalisation)}} € H.T / mois*</small>
                          </div>
                          <ul class="features">
                            <li>Logo</li>
                            <li>Avatar</li>
                            <li>Genre</li>
                            <li>Wizz Reply «Étudiant» - I.A</li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-12">
                      <div class="card card-apply-job">
                        <div class="card-body" style="padding:5px">
                          <div class="apply-job-package bg-light-primary rounded">
                            <div>
                              <h2 class="d-inline me-25">Tracking</h2>
                            </div>
                            <small>{{pricePerMonth(productStore.tracking)}} € H.T / mois*</small>
                          </div>
                          <ul class="features">
                            <li>Origine des leads</li>
                            <li>Programmes visités</li>
                            <li>Pages visitées</li>
                            <li>TOP 5 programmes visités / Catégorie</li>
                            <li>Lead Score</li>
                            <li>Filtrage géographique</li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-12">
                      <div class="card card-apply-job">
                        <div class="card-body" style="padding:5px">
                          <div class="apply-job-package bg-light-primary rounded">
                            <div>
                              <h2 class="d-inline me-25">Entreprises</h2>
                            </div>
                            <small>{{pricePerMonth(productStore.entreprise)}} € H.T / mois*</small>
                          </div>
                          <ul class="features">
                            <li>Wizz Reply «Entreprises»</li>
                            <li>Leads entreprises</li>
                            <li>Posts «Entreprises»</li>
                            <li>Extraction Leads</li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-12">
                      <div class="card card-apply-job">
                        <div class="card-body" style="padding:5px">
                          <div class="apply-job-package bg-light-primary rounded">
                            <div>
                              <h2 class="d-inline me-25">Connexion CRM</h2>
                            </div>
                            <small>{{pricePerMonth(productStore.crm)}} € H.T / mois*</small>
                          </div>
                          <ul class="features">
                            <li>Connexion aux API</li>
                            <li>Personnalisation des tables</li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-12">
                      <div class="card card-apply-job">
                        <div class="card-body" style="padding:5px">
                          <div class="apply-job-package bg-light-primary rounded">
                            <div>
                              <h2 class="d-inline me-25">Support</h2>
                            </div>
                            <small>{{pricePerMonth(productStore.support)}} € H.T / mois*</small>
                          </div>
                          <ul class="features">
                            <li>Contacter notre équipe support</li>
                            <li>Demandes spécifiques</li>
                          </ul>
                        </div>
                      </div>
                    </div>


                    <div class="col-lg-4 col-md-6 col-12">
                      </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="row">
                <div class="col-12 col-lg-10 col-lg-offset-3 mx-auto">
                  <div
                      class="pricing-trial-content d-flex justify-content-between"
                  >
                    <div class="text-center mt-3 flex-grow-1">

                      <a href="/CGV_2024_2_Lead-ia.pdf"><h4 class="text-primary">📄 Télécharger les CGV</h4></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="pricing-free-trial">
              <div class="row">
                <div class="col-12 col-lg-10 col-lg-offset-3 mx-auto">
                  <div
                          class="pricing-trial-content d-flex justify-content-between"
                  >
                    <div class="text-center text-md-start mt-3">
                      <h3 class="text-primary" style="font-size: 1.3em;margin-top:20px">

                        Lead-ia vous accompagne TOUTE l'année pour RÉUSSIR !
                      </h3>

                      <a class="btn btn-primary mt-2 mt-lg-3 waves-effect waves-float waves-light"
                         :href="'mailto:sales@lead-ia.com?Body=Bonjour,%0AJe suis '+userStore.current_user.firstname+' de '+accountStore.account.name+', pourriez-vous me recontacter rapidement, s’il vous plaît ?&Subject=Demande%20d\'informations'">Contacter l’équipe commerciale</a>
                    </div>
                    <img
                            src="@/app-assets/images/illustration/pricing-Illustration.svg"
                            class="pricing-trial-img img-fluid"
                            alt="svg img"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </DashboardLayout>
  </div>
</template>

<script>
    import DashboardLayout from "@/layouts/dashboard";
    import { useProductStore } from "@/stores/product";
    import { useUserStore } from "@/stores/user";
    import { useAccountStore } from "@/stores/account";
    import { useLicenceStore } from "@/stores/licence";
    import { useAuthStore } from "@/stores/auth";

    export default {
        name: "product",
        components: {
            DashboardLayout
        },
        data() {
            return {
                current_page: "",
                current_plan: this.productStore.lead_1500,
                tracking_selected: false,
                personnalisation_selected: false,
                interaction_selected: false,
                entreprise_selected: false,
                crm_selected: false,
                sms_selected: false,
                support_selected: false,
            };
        },
        async mounted() {
            await this.productStore.getProducts({ vm: this });
            await this.licenceStore.getLicences({ vm: this });
            this.current_plan = this.productStore.lead_500;
            this.resetModuleSelection();
        },
        setup() {
            const accountStore = useAccountStore();
            const productStore = useProductStore();
            const authStore = useAuthStore();
            const userStore = useUserStore();
            const licenceStore = useLicenceStore();
            return {
                accountStore,
                productStore,
                authStore,
                userStore,
                licenceStore
            };
        },
        watch: {
            "licenceStore.chatbot_end": function (newFilter, oldFilter) {
                this.resetModuleSelection();
            },
        },
        methods: {
            enableModuleOnly(){
                return this.licenceStore.chatbot_days_left > 90;
            },
            resetModuleSelection(){
                this.current_page = "pack";
                if(this.enableModuleOnly()){
                    this.current_page = "carte";
                    this.tracking_selected = this.licenceStore.chatbot_tracking;
                    this.personnalisation_selected = this.licenceStore.chatbot_personnalisation;
                    this.interaction_selected = this.licenceStore.chatbot_interaction;
                    this.entreprise_selected = this.licenceStore.chatbot_entreprise;
                    this.crm_selected = this.licenceStore.chatbot_crm;
                    this.sms_selected = this.licenceStore.chatbot_sms;
                    this.support_selected = this.licenceStore.chatbot_support;
                }
            },
            async checkoutPack(plan){
                const data = {
                    line_items: JSON.stringify([{price: plan.stripe_price, quantity: 1}]),
                    payment_method_types: ['sepa_debit'],
                    vm: this,
                };
                await this.productStore.getPlanCheckout(data);
                location.href = this.productStore.checkout.stripe_session_url;
            },
            async checkoutCustom(){

                var line_items = [];
                if( ! this.enableModuleOnly() ) {
                    line_items.push({price: this.current_plan.stripe_price, quantity: 1});
                }

                if(this.tracking_selected && !(this.enableModuleOnly() && this.licenceStore.chatbot_tracking)){
                    line_items.push({price: this.productStore.tracking.stripe_price, quantity: 1});
                }
                if(this.interaction_selected && !(this.enableModuleOnly() && this.licenceStore.chatbot_interaction)){
                    line_items.push({price: this.productStore.interaction.stripe_price, quantity: 1});
                }
                if(this.personnalisation_selected && !(this.enableModuleOnly() && this.licenceStore.chatbot_personnalisation)){
                    line_items.push({price: this.productStore.personnalisation.stripe_price, quantity: 1});
                }
                if(this.entreprise_selected && !(this.enableModuleOnly() && this.licenceStore.chatbot_entreprise)){
                    line_items.push({price: this.productStore.entreprise.stripe_price, quantity: 1});
                }
                if(this.crm_selected && !(this.enableModuleOnly() && this.licenceStore.chatbot_crm)){
                    line_items.push({price: this.productStore.crm.stripe_price, quantity: 1});
                }
                if(this.sms_selected && !(this.enableModuleOnly() && this.licenceStore.chatbot_sms)){
                    line_items.push({price: this.productStore.starter_sms.stripe_price, quantity: 1});
                }
                if(this.support_selected && !(this.enableModuleOnly() && this.licenceStore.chatbot_support)){
                    line_items.push({price: this.productStore.support.stripe_price, quantity: 1});
                }

                const data = {
                    line_items: JSON.stringify(line_items),
                    payment_method_types: ['sepa_debit'],
                    vm: this,
                };
                await this.productStore.getPlanCheckout(data);
                location.href = this.productStore.checkout.stripe_session_url;
            },
            pricePerMonth(x) {
                if (x == null){
                    return "";
                }
                return (x.price / 12 / 100).toFixed(1);
            },
            numberWithCommas(x) {
                if (x == null){
                    return "";
                }
                x = x / 100;
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },
            selectionTotal() {
                var total = 0;
                if( ! this.enableModuleOnly() ) {
                    total += this.current_plan.price;
                }
                if(this.tracking_selected && !(this.enableModuleOnly() && this.licenceStore.chatbot_tracking)){
                    total += this.productStore.tracking.price;
                }
                if(this.interaction_selected && !(this.enableModuleOnly() && this.licenceStore.chatbot_interaction)){
                    total += this.productStore.interaction.price;
                }
                if(this.personnalisation_selected && !(this.enableModuleOnly() && this.licenceStore.chatbot_personnalisation)){
                    total += this.productStore.personnalisation.price;
                }
                if(this.entreprise_selected && !(this.enableModuleOnly() && this.licenceStore.chatbot_entreprise)){
                    total += this.productStore.entreprise.price;
                }
                if(this.crm_selected && !(this.enableModuleOnly() && this.licenceStore.chatbot_crm)){
                    total += this.productStore.crm.price;
                }
                if(this.sms_selected && !(this.enableModuleOnly() && this.licenceStore.chatbot_sms)){
                    total += this.productStore.starter_sms.price;
                }
                if(this.support_selected && !(this.enableModuleOnly() && this.licenceStore.chatbot_support)){
                    total += this.productStore.support.price;
                }
                return total;
            }
        }
    };
</script>

<style scoped>

  .accordion-header button {
    color: #7367f0;
  }
  .collapsed {
    color: #000 !important;
  }
  .table-striped > tbody > tr:nth-of-type(2N+2) > .table-primary {
    --bs-table-accent-bg: #f7f6ff !important;
  }
  .table-striped > tbody > tr:nth-of-type(2N+1) > .table-primary {
    --bs-table-accent-bg: #eeebfc !important;
  }
  .table-striped > tbody > tr:nth-of-type(1) > * {
    --bs-table-accent-bg: #fff !important;
  }
  .table-striped > tbody > tr:nth-of-type(1) > .popular {
    --bs-table-accent-bg: #7366f0 !important;
    color: #fff;
    border-top-left-radius: 8px 8px;
    border-top-right-radius: 8px 8px;
    padding: 4px;
    font-size: 12px;
  }
  .table-striped > tbody > tr:nth-of-type(2) > .regular-pricing {
    --bs-table-accent-bg: #f7f6ff !important;
  }

  .pricing-basic-value {
    font-size: 50px;
  }
  .pricing-duration {
    margin-top:-10px;
  }
  .annual-plan {
    padding:0px
  }
  .table-default {
    font-size: 16px !important;
  }

  .pricing-table {
    padding: 5px;
  }
  .popular {
    background-color: #7366f0 !important;
    color: #fff;
    border-top-left-radius: 8px 8px;
    border-top-right-radius: 8px 8px;
    padding: 4px;
    font-size: 12px;
  }
  .border_shadow{
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 10px !important;
  }
  .custom-option-item-left{
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 10px !important;
  }
  .custom-option-item-right{
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 10px !important;
  }
  .custom-option-item-check + .custom-option-item {
    color: #7367F0;
    background-color: rgba(115,103,240,.12);
    border-color: #7367F0;
  }
  .custom-option-item-check:checked + .custom-option-item {
    color: #fff;
    background-color: #7367F0;
    border-color: #7367F0;
  }

  .v-selection-control__input input {
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  ul.features {
    list-style: none;
  }

  ul.features li:before {
    content: '✓  ';
    margin-left:-15px
  }

  .card-module {
    height:60px;
  }
  .card-module .form-check-label {
    position: absolute;
    width: 100%;
    padding:20px 20px 20px 14px;
  }
  .card-module input {
    margin-top:20px;
    margin-left:20px;
  }
  .card-module .check-price {
    float:right;
    padding:20px;
  }
</style>
