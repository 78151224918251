<template>
  <div>
    <DashboardLayout>
      <div class="container">
        <div class="support-content text-center">
          <h1 class="mb-4">Besoin de support évolutif ?</h1>

          <p class="mb-4">
            Si vous avez des demandes d'évolution ou de fonctionnalités
            spécifiques, notre équipe est là pour vous aider.
          </p>

          <p class="mb-4">
            Cliquez sur le lien ci-dessous pour accéder à notre formulaire de
            demande de support évolutif et nous faire part de vos besoins. Nous
            traiterons votre demande dans les meilleurs délais.
          </p>

          <a :href="formLink" class="support-link" target="_blank">
            <i class="fas fa-link me-2"></i>
            Accéder à la demande de support évolutif
          </a>
        </div>
      </div>
    </DashboardLayout>
  </div>
</template>

<script>
import DashboardLayout from "@/layouts/dashboard";

export default {
  name: "Support",
  components: {
    DashboardLayout,
  },
  data() {
    const formLink =
      process.env.VUE_APP_TYPEFORM_SUPPORT_LINK ||
      "https://29oea0jrsnv.typeform.com/to/QfaHB2t4";
    return { formLink };
  },
};
</script>

<style scoped>
.container {
  min-height: calc(100vh - 100px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.support-content {
  max-width: 800px;
  padding: 2rem;
}

h1 {
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
}

p {
  font-size: 1.25rem;
  line-height: 1.6;
  color: #4b4b4b;
}

.support-link {
  display: inline-flex;
  align-items: center;
  color: #0066cc;
  text-decoration: none;
  font-weight: 500;
  font-size: 1.25rem;
}

.support-link:hover {
  text-decoration: underline;
}
</style>
