// For ES Module via npm/yarn, or with import maps
import Appsignal from "@appsignal/javascript";
import { plugin as pluginNetwork } from "@appsignal/plugin-breadcrumbs-network";

const key: string | false = process.env.VUE_APP_APPSIGNAL_FRONT_KEY || false;

let appsignal: Appsignal | null = null;
if (key) {
  appsignal = new Appsignal({
    namespace: "frontend",
    revision: process.env.VUE_APP_COMMIT_SHORT_SHA,
    key: key,
  });
  appsignal.use(pluginNetwork({}));
}

export { appsignal };
