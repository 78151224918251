<template>
  <div>
    <div
      class="main-menu menu-fixed menu-light menu-accordion menu-shadow"
      data-scroll-to-active="true"
      v-bind:style="{ width: menuExtended }"
    >
      <div class="navbar-header">
        <ul class="nav navbar-nav flex-row">
          <li class="nav-item me-auto">
            <a class="navbar-brand logo link" @click="$router.push('/')"> </a>
          </li>
        </ul>
      </div>
      <div class="shadow-bottom"></div>
      <div class="main-menu-content">
        <ul
          class="navigation navigation-main"
          id="main-menu-navigation"
          data-menu="menu-navigation"
        >
          <li class="nav-item">
            <router-link class="d-flex align-items-center" :to="{ path: '/' }" @click="layoutStore.resetActive()">
              <div class="menu-container">
                <span class="menu-title">
                  <vue-feather type="home"></vue-feather> </span
                >Dashboard
              </div>
            </router-link>
          </li>
          <li class="nav-item" :class="{active: layoutStore.menu.contact}">
            <router-link
              class="d-flex align-items-center"
              :to="{ path: '/contact' }"
              @click="layoutStore.resetActive()"
            >
              <div class="menu-container">
                <span class="menu-title">
                  <vue-feather type="user"></vue-feather> </span
                >Contacts
              </div>
            </router-link>
          </li>
          <li class="nav-item" :class="{active: layoutStore.menu.program}">
            <router-link
              class="d-flex align-items-center"
              :to="{ path: '/program' }"
              @click="layoutStore.resetActive()"
            >
              <div class="menu-container">
                <span class="menu-title">
                  <vue-feather type="grid"></vue-feather> </span
                >Programmes
              </div>
            </router-link>
          </li>
          <li class="nav-item" :class="{active: layoutStore.menu.entreprise}">
            <router-link
              class="d-flex align-items-center"
              :to="{ path: '/entreprise' }"
              @click="layoutStore.resetActive()"
            >
              <div class="menu-container">
                <span class="menu-title">
                  <vue-feather type="flag"></vue-feather> </span
                >Entreprises
              </div>
            </router-link>
          </li>
          <li
            class="nav-item has-sub"
            :class="{ open: layoutStore.menu.campagne }"
            @click="layoutStore.resetActive()"
          >
            <a
              class="d-flex align-items-center"
              @click="layoutStore.openMenu('campagne')"
              ><div class="menu-container">
                <span class="menu-title"
                  ><vue-feather type="volume-1"></vue-feather></span
                >Campagnes
              </div><span class="badge badge-light-success"> New </span></a
            >
            <ul class="submenu">
              <li class="nav-item">
                <router-link
                  class="d-flex align-items-center"
                  :class="layoutStore.menu.banniere"
                  :to="{ path: '/campagne/banniere' }"
                >
                  <div class="menu-container">
                    <span class="menu-title">
                      <vue-feather type="circle"></vue-feather> </span
                    >Bannières
                  </div>
                  <span class="circle">{{ nbBannieres }}</span>
                </router-link>
              </li>
            </ul>
            <ul class="submenu">
              <li class="nav-item">
                <router-link
                        class="d-flex align-items-center"
                        :class="layoutStore.menu.sms_setting"
                        :to="{ path: '/campagne/sms' }"
                >
                  <div class="menu-container">
                    <span class="menu-title">
                      <vue-feather type="circle"></vue-feather> </span
                    >Magic SMS
                  </div>
                  <span class="badge badge-light-success new-sms"> New </span>
                  <!--span class="circle">{{ nbSms }}</span-->
                </router-link>
              </li>
            </ul>
          </li>
          <li
            class="nav-item has-sub"
            :class="{ open: layoutStore.menu.smartReply }"
            @click="layoutStore.resetActive()"
          >
            <a
              class="d-flex align-items-center"
              @click="layoutStore.openMenu('smartReply')"
              ><div class="menu-container">
                <span class="menu-title"
                  ><vue-feather type="message-square"></vue-feather></span
                >Wizz Reply
              </div><span v-if="accountStore.account.beta" class="badge badge-light-success"> BETA </span></a
            >
            <ul class="submenu">
              <li class="nav-item">
                <router-link
                  class="d-flex align-items-center"
                  :to="{ path: '/smartreply/etudiant' }"
                >
                  <div class="menu-container">
                    <span class="menu-title">
                      <vue-feather type="circle"></vue-feather> </span
                    >Etudiants
                  </div>
                  <span v-if="accountStore.account.beta" class="badge badge-light-success badge-light-beta-sub"> BETA </span>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  class="d-flex align-items-center"
                  :to="{ path: '/smartreply/entreprise' }"
                >
                  <div class="menu-container">
                    <span class="menu-title">
                      <vue-feather type="circle"></vue-feather> </span
                    >Entreprises
                  </div>
                  <span v-if="accountStore.account.beta" class="badge badge-light-success badge-light-beta-sub"> BETA </span>
                </router-link>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <router-link
                    class="d-flex align-items-center"
                    :to="{ path: '/settings' }"
                    @click="layoutStore.resetActive()"
            >
              <div class="menu-container">
                <span class="menu-title">
                  <vue-feather type="settings"></vue-feather> </span
                >Settings
              </div>
            </router-link>
          </li>
          <li
                  class="nav-item has-sub"
                  :class="{ open: layoutStore.menu.price }"
                  @click="layoutStore.resetActive()"
          >
            <a
                    class="d-flex align-items-center"
                    @click="layoutStore.openMenu('price')"
            ><div class="menu-container">
                <span class="menu-title"
                ><vue-feather type="credit-card"></vue-feather></span
                >Plans et Tarifs
            </div></a
            >
            <ul class="submenu">
              <li class="nav-item">
                <router-link
                        class="d-flex align-items-center"
                        :class="layoutStore.menu.banniere"
                        :to="{ path: '/pricing' }"
                >
                  <div class="menu-container">
                    <span class="menu-title">
                      <vue-feather type="circle"></vue-feather> </span
                    >Chatbot
                  </div>
                </router-link>
              </li>
            </ul>
            <ul class="submenu">
              <li class="nav-item">
                <router-link
                        class="d-flex align-items-center"
                        :class="layoutStore.menu.sms_setting"
                        :to="{ path: '/pricing-sms' }"
                >
                  <div class="menu-container">
                    <span class="menu-title">
                      <vue-feather type="circle"></vue-feather> </span
                    >Magic SMS
                  </div>
                </router-link>
              </li>
            </ul>
          </li>

          <li class="nav-item">
            <router-link
              class="d-flex align-items-center"
              :to="{ path: '/support' }"
              @click="layoutStore.resetActive()"
            >
              <div class="menu-container">
                <span class="menu-title">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" width="24" height="24" stroke-width="2">
                    <path d="M10 5m0 2a2 2 0 0 1 2 -2h6a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-6a2 2 0 0 1 -2 -2z"></path>
                    <path d="M7 7l0 10"></path>
                    <path d="M4 8l0 8"></path>
                  </svg>
                </span>
                Support
              </div>
            </router-link>
          </li>

        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { useLayoutStore } from "@/stores/layout";
import { useCampagneStore } from "@/stores/campagne";
import { useSmsSettingStore } from "@/stores/smsSetting";
import { useUserStore } from "@/stores/user";
import { useLicenceStore } from "@/stores/licence";
import { useAccountStore } from "@/stores/account";

export default {
  name: "dashboardSidebar",
  data() {
    return {
      menu: {
        price: false,
        campagne: false,
        smartReply: false,
      },
    };
  },
  setup() {
    const layoutStore = useLayoutStore();
    const campagneStore = useCampagneStore();
    const smsSettingStore = useSmsSettingStore();
    const userStore = useUserStore();
    const licenceStore = useLicenceStore();
    const accountStore = useAccountStore();
    return { layoutStore, campagneStore, userStore, smsSettingStore, licenceStore, accountStore };
  },
  computed: {
    nbBannieres() {
      return this.campagneStore.bannieres.length
    },
    nbSms() {
      return this.smsSettingStore.sms_settings.length
    }
  }
};
</script>

<style lang="scss" scoped>
.menuExtender {
  width: 100%;
  margin-bottom: 5px;
}

.menuExtender svg {
  position: absolute;
  right: 10px;
}

.navbar-brand {
  margin: 30px 0 0 0;
}

.transitions {
  transition: width 1s, opacity 1s !important;
  -moz-transition: width 1s, opacity 1s !important;
  -webkit-transition: width 1s, opacity 1s !important;
  -o-transition: width 1s, opacity 1s !important;
  -ms-transition: width 1s, opacity 1s !important;
}

.main-menu .navbar-header .navbar-brand {
  margin-top: 0;
}

.logo {
  margin-left: -10px;
  width: 160px;
  height: 65px;
  transition: margin-left 0.5s linear, opacity 1s;
  -moz-transition: margin-left 0.5s linear, opacity 1s;
  -webkit-transition: margin-left 0.5s linear, opacity 1s;
  -o-transition: margin-left 0.5s linear, opacity 1s;
  background-image: url("../../assets/images/logo.png");
  background-size: 100% 100%;
}

.navigation li a i,
.navigation li a svg {
  height: 40px;
  width: 18px;
  font-size: 15px;
  margin-right: 1.38rem;
  display: inline-block;
}

.main-menu .navbar-header {
  padding: 0;
}

.main-menu .main-menu-content {
  height: 100%;
  position: relative;
  top: 20px;
}

.main-menu.menu-light .navigation li a {
  padding: 0 15px;
}

.navigation li a i,
.navigation li a svg {
  height: 42px;
  width: 22px;
}

.main-menu.menu-light .navigation > li .active {
  background: linear-gradient(118deg, #7367f0, rgba(115, 103, 240, 0.7));
  box-shadow: 0 0 10px 1px rgb(115 103 240 / 70%);
  border-radius: 4px;
  z-index: 1;
  color: #fff;
}

.menu-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navigation {
  font-size: 0.938rem !important;
  font-weight: 500;
}

.circle {
  position: absolute;
  right: 10px;
  border-radius: 50%;
  background-color: #7367f0;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
.badge-light-success {
  position: absolute;
  right: 42px;
  font-size:10px !important;
  padding:4px 7px 4px 7px  !important;
  background-color: #d8ffc6 !important;
  color: #28C76F !important;
}
.badge-light-beta {
  font-size:9px !important;
}
.badge-light-beta-sub {
  right: 10px !important;
  font-size:9px !important;
}
.new-sms{
  position: absolute  !important;
  right: 10px !important;
  background-color: #d8ffc6 !important;
  color: #28C76F !important;
}

.main-menu.menu-light .navigation > li:last-child {
  position: fixed;
  bottom: 20px;
  width: 260px;
  
  a {
    // c'est un hack: je ne peux pas utiliser l'héritage de width à cause de position: fixed...
    width: calc(100% - 40px);
    margin: 0 15px;
  }
}
</style>
