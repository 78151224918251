import { createWebHistory, createRouter } from "vue-router";
import { useAuthStore } from "@/stores/auth";
import Home from "@/views/Home.vue";
import Contact from "@/views/Contact.vue";
import ContactDetail from "@/views/ContactDetail.vue";
import Program from "@/views/Program.vue";
import ProgramDetail from "@/views/ProgramDetail.vue";
import Entreprise from "@/views/Entreprise.vue";
import EntrepriseDetail from "@/views/EntrepriseDetail.vue";
import Banniere from "@/views/campagne/Banniere.vue";
import BanniereDetail from "@/views/campagne/BanniereDetail.vue";
import SmsSettings from "@/views/campagne/SmsSettings.vue";
import SmsDashboard from "@/views/campagne/SmsDashboard.vue";
import SmsTemplates from "@/views/campagne/SmsTemplates.vue";
import SmsHistorique from "@/views/campagne/SmsHistorique.vue";
import TemplateDetail from "@/views/campagne/SmsTemplateDetail.vue";
import TemplateMagic from "@/views/campagne/SmsTemplateMagic.vue";
import SmsSettingFirst from "@/views/campagne/SmsSettingFirst.vue";
import SmsSettingDetail from "@/views/campagne/SmsSettingDetail.vue";
import SmartEtudiant from "@/views/smartreply/Etudiant.vue";
import SmartEntreprise from "@/views/smartreply/Entreprise.vue";
import Pricing from "@/views/Pricing.vue";
import PricingSms from "@/views/PricingSms.vue";
import AccountSetting from "@/views/AccountSetting.vue";
import Setting from "@/views/Setting.vue";
import SettingGeneral from "@/views/setting/general.vue";
import SettingChatbot from "@/views/setting/chatbot.vue";
import SettingAccount from "@/views/setting/account.vue";
import SettingIntegration from "@/views/setting/integration.vue";
import SettingLead from "@/views/setting/lead.vue";
import SettingTrial from "@/views/setting/trial.vue";
import ChatBotAccueil from "@/views/setting/chatbox/accueil.vue";
import ChatBotVisuel from "@/views/setting/chatbox/visuel.vue";
import ChatBotQualification from "@/views/setting/chatbox/qualification.vue";
import ChatBotBranche from "@/views/setting/chatbox/branche.vue";
import ChatBotQuestion from "@/views/setting/chatbox/question.vue";
import ChatBotActivation from "@/views/setting/chatbox/activation.vue";
import Signin from "@/views/Signin.vue";
import Password from "@/views/Password.vue";
import HealthCheck from "@/views/HealthCheck.vue";
import NotFound from "@/components/NotFound.vue";
import Support from "@/views/Support.vue";

const routes = [
  {
    path: "/",
    name: "HomePage",
    component: Home,
    meta: { requireAuth: true },
  },
  {
    path: "/health",
    name: "HealthCheck",
    component: HealthCheck,
    meta: { requireAuth: false },
  },
  {
    path: "/password",
    name: "Password",
    component: Password,
    meta: { requireAuth: false },
  },
  {
    path: "/contact",
    name: "Contacts",
    component: Contact,
    meta: { requireAuth: true },
  },
  {
    path: "/contact/:id",
    name: "ContactDetail",
    component: ContactDetail,
    meta: { requireAuth: true },
  },
  {
    path: "/program",
    name: "Program",
    component: Program,
    meta: { requireAuth: true },
  },
  {
    path: "/program/add",
    name: "ProgramAdd",
    component: ProgramDetail,
    meta: { requireAuth: true },
  },
  {
    path: "/program/:id",
    name: "ProgramDetail",
    component: ProgramDetail,
    meta: { requireAuth: true },
  },
  {
    path: "/entreprise",
    name: "Entreprise",
    component: Entreprise,
    meta: { requireAuth: true },
  },
  {
    path: "/entreprise/:id",
    name: "EntrepriseDetail",
    component: EntrepriseDetail,
    meta: { requireAuth: true },
  },
  {
    path: "/campagne/banniere",
    name: "Banniere",
    component: Banniere,
    meta: { requireAuth: true },
  },
  {
    path: "/campagne/banniere/add",
    name: "BanniereAdd",
    component: BanniereDetail,
    meta: { requireAuth: true },
  },
  {
    path: "/campagne/banniere/:id",
    name: "BanniereDetail",
    component: BanniereDetail,
    meta: { requireAuth: true },
  },
  {
    path: "/campagne/sms",
    name: "SmsSettings",
    components: {
      default: SmsSettings,
      setting: SmsSettings,
    },
    children: [
      {
        path: "",
        name: "Dashboard",
        components: {
          default: SmsSettings,
          setting: SmsSettings,
          sms: SmsDashboard,
        },
      },
      {
        path: "templates",
        name: "Templates",
        components: {
          default: SmsSettings,
          setting: SmsSettings,
          sms: SmsTemplates,
        },
      },
      {
        path: "historique",
        name: "Historique",
        components: {
          default: SmsSettings,
          setting: SmsSettings,
          sms: SmsHistorique,
        },
      },
      {
        path: "template/:id",
        name: "TemplateDetail",
        components: {
          default: SmsSettings,
          setting: SmsSettings,
          sms: TemplateDetail,
        },
      },
      {
        path: "template/:id/magic",
        name: "TemplateMagic",
        components: {
          default: SmsSettings,
          setting: SmsSettings,
          sms: TemplateMagic,
        },
      },
      {
        path: "template/:id/magic/reload",
        name: "TemplateMagicReload",
        components: {
          default: SmsSettings,
          setting: SmsSettings,
          sms: TemplateMagic,
        },
      },
      {
        path: "template/:id/sample/:sample/settings/:sms_setting_id",
        name: "SmsSettingFirst",
        components: {
          default: SmsSettings,
          setting: SmsSettings,
          sms: SmsSettingFirst,
        },
      },
      {
        path: "settings/:id",
        name: "SmsSettingDetail",
        components: {
          default: SmsSettings,
          setting: SmsSettings,
          sms: SmsSettingDetail,
        },
      },
    ],
  },
  {
    path: "/smartreply/etudiant",
    name: "SmartEtudiant",
    component: SmartEtudiant,
    meta: { requireAuth: true },
  },
  {
    path: "/smartreply/entreprise",
    name: "SmartEntreprise",
    component: SmartEntreprise,
    meta: { requireAuth: true },
  },
  {
    path: "/pricing",
    name: "Pricing",
    component: Pricing,
    meta: { requireAuth: true },
  },
  {
    path: "/pricing-sms",
    name: "PricingSms",
    component: PricingSms,
    meta: { requireAuth: true },
  },
  {
    path: "/account_setting",
    name: "AccountSetting",
    component: AccountSetting,
    meta: { requireAuth: true },
  },
  {
    path: "/settings",
    name: "Setting",
    components: {
      default: Setting,
      setting: SettingGeneral,
    },
    children: [
      {
        path: "",
        name: "General",
        components: {
          default: Setting,
          setting: SettingGeneral,
        },
      },
      {
        path: "chatbot",
        name: "Chatbot",
        components: {
          default: Setting,
          setting: SettingChatbot,
        },
        children: [
          {
            path: "",
            name: "Accueil",
            components: {
              default: Setting,
              setting: SettingGeneral,
              chatbot: ChatBotAccueil,
            },
          },
          {
            path: "visuel",
            name: "Visuel",
            components: {
              default: Setting,
              setting: SettingGeneral,
              chatbot: ChatBotVisuel,
            },
          },
          {
            path: "qualification",
            name: "Qualification",
            components: {
              default: Setting,
              setting: SettingGeneral,
              chatbot: ChatBotQualification,
            },
          },
          {
            path: "branche",
            name: "Branche",
            components: {
              default: Setting,
              setting: SettingGeneral,
              chatbot: ChatBotBranche,
            },
          },
          {
            path: "question",
            name: "Question",
            components: {
              default: Setting,
              setting: SettingGeneral,
              chatbot: ChatBotQuestion,
            },
          },
          {
            path: "activation",
            name: "Activation",
            components: {
              default: Setting,
              setting: SettingGeneral,
              chatbot: ChatBotActivation,
            },
          },
        ],
      },
      {
        path: "account",
        name: "Account",
        components: {
          default: Setting,
          setting: SettingAccount,
        },
      },
      {
        path: "integration",
        name: "Integration",
        components: {
          default: Setting,
          setting: SettingIntegration,
        },
      },
      {
        path: "lead",
        name: "Lead",
        components: {
          default: Setting,
          setting: SettingLead,
        },
      },
      {
        path: "trial",
        name: "Trial",
        components: {
          default: Setting,
          setting: SettingTrial,
        },
      },
    ],
    meta: { requireAuth: true },
  },
  {
    path: "/signin",
    name: "SigninPage",
    component: Signin,
  },
  {
    path: "/support",
    name: "Support",
    component: Support,
    meta: { requireAuth: true },
  },
  {
    path: "/:catchAll(.*)",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
  if (to.meta.requireAuth && !useAuthStore().isAuthenticated) {
    next({ path: "/signin" });
  } else next();
});

export default router;
